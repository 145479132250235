import React from 'react';

function ResponsiveSpacing({
  block: {
    config: { className },
  },
}) {
  return (
    <div aria-hidden={true} data-testid='spacing' className={className}></div>
  );
}

export default ResponsiveSpacing;
